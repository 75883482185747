import React from "react";
import "./CaseStudyContent.scss";

const CaseStudyContentSplit = (props) => {
  return (
    <div className={`case-study-content content-split`}>
      {props.content.headline.text && (
        <div className="row">
          <div className="split-header col-md-10 col-md-offset-1">
            <h3 className="heading heading--l text--medium">
              {props.content.headline.text}
            </h3>
          </div>
        </div>
      )}
      <div
        className={`split-content row ${
          props.content.reverse_columns ? "reverse" : ""
        }`}
      >
        <div className="col-md-6 col">
          <div>
            {props.items.map((item, index) => {
              return (
                <>
                  {index !== 0 && (
                    <div className="case-study-content__spacer medium"></div>
                  )}
                  <div className="case-study-content__media">
                    <img src={item.image.url} />
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="col-md-6 col">
          <div className="case-study-content__content">
            <div
              className="case-study__rich-text"
              dangerouslySetInnerHTML={{ __html: props.content.content.html }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CaseStudyContentSplit;
