import React from "react";
import { Link } from "gatsby";
import "./WorkLinkItem.scss";
const WorkLinkItem = ({ size, type, title, route, thumb }) => {
  return (
    <Link to={route} className={`work-link-item ${size} ${type}`}>
      <div className="work-link-item__inner">
        <div
          className="work-link-item__background"
          style={{ backgroundImage: `url('${thumb}')` }}
        ></div>
        <div className="work-link-item__content">
          <div className="work-link-item__content-inner">
            <h4 className="title text--black">
              {type === "prev" && (
                <img
                  className="prev-icon"
                  src={require("./../assets/svg/arrow-back.svg")}
                  alt="Arrow Back"
                />
              )}
              <span>{title}</span>
              {type === "next" && (
                <img
                  className="next-icon"
                  src={require("./../assets/svg/arrow-back.svg")}
                  alt="Arrow Back"
                />
              )}
            </h4>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default WorkLinkItem;
