import React from "react";
import { Link } from "gatsby";
import { Waypoint } from "react-waypoint";

import "./NewsItem.scss";
const NewsItemRelated = ({ size, related, node }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  const linkTo = node.type === 'case_study' ? `/case-study/${node.uid}/` : `/article/${node.uid}/`;

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`news-item size-${size} ${
          related ? "related-item" : ""
        } waypoint ${active ? "waypoint-active" : ""}`}
      >
        <Link to={linkTo} className="news-item__link">
          <div className="news-item__media">
            {node.data.thumb && node.data.thumb.fixed && (
              <img src={node.data.thumb.fixed.src} alt={node.data.thumb.fixed.alt} />
            )}
          </div>
          <div className="news-item__content">
            <div className="text-center">
              <h4 className="heading heading--s text--black">
                {node.data.title.text}
              </h4>
              {related !== true && (
                <div
                  className="text text--md"
                  dangerouslySetInnerHTML={{ __html: node.data.summary.html }}
                ></div>
              )}
            </div>
          </div>
        </Link>
      </div>
    </Waypoint>
  );
};
export default NewsItemRelated;
