import React from "react";
import "./Related.scss";
import NewsItem from "./NewsItem";
import NewsItemRelated from "./NewsItemRelated";
import WorkLinkItem from "./WorkLinkItem";
const Related = ({ items, type, label }) => {
  return (
    <div className="related">
      <h5 className="heading heading--xxs text--regular uppercase align-center">
        {label}
      </h5>
      <div className="related-items">
        {items.map((item, index) => {
          if (type === "article") {
            return (
              <NewsItem
                related={true}
                size="small"
                key={`newsitem${index}`}
                node={item}
              />
            );
          } else if (type === "related-study") {
            return (
              <NewsItemRelated
                related={true}
                size="small"
                key={`newsitem${index}`}
                node={item.case_study.document}
              />
            );
          } else {
            if (!item.case_study.document) {
              return;
            }
            return (
              <WorkLinkItem
                title={item.case_study.document.data.title.text}
                route={`/case-study/${item.case_study.document.uid}`}
                thumb={item.case_study.document.data.thumb.fluid.src}
                type={index === 0 ? "prev" : "next"}
                size="small"
                key={`worklinkitem${index}`}
              />
            );
          }
        })}
      </div>
    </div>
  );
};
export default Related;
